import React from 'react';

import Step4 from 'src/features/onboarding/steps/step4';

import SEO from 'src/components/seo';

export default function Onboarding() {
  return (
    <>
      <SEO title="Step 4" />

      <Step4 />
    </>
  );
}
